.site-footer {
	@include site-row();

	font-size: 1.4rem;
	background-color: $color-primary;
	color: #FFF;
	border-top: 2px solid #FFF;
	box-shadow: 0 -6rem 0 0 color(silver);

	margin-top: 6.2rem;

	&__content {
		@include site-row-main-content();
		@include prevent-grid-expanding();

		display: grid;
		grid-gap: $grid-gap;
		grid-template-columns: 1fr;

		padding: 3rem 0;

		@include IE-only() {
			display: block;
		}

		@include breakpoint-min(xs) {
			grid-template-columns: repeat(2, 1fr);

			@include IE-only() {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				> * {
					width: 50%;
					padding: $grid-gap/2;
				}
			};
		}

		@include breakpoint-min(md) {
			grid-template-columns: repeat(3, 1fr);

			@include IE-only() {
				> * {
					width: 33.33%;
				}
			};
		}

		//@include breakpoint-min(lg) {
		//	grid-template-columns: repeat(4, 1fr);
		//}
	}

	&__box-heading {
		margin-top: 0;
		font-size: 1.8rem;
		font-weight: normal
	}

	&__box-content {
		margin-bottom: 3.5rem;
	}
}

.meta-navigation {
	@include site-row-full-width();
	@include site-row();
	background-color: color(neutral-light);
	color: $color-primary;

	&__content {
		@include site-row-main-content();

		padding: 1.2rem;
		text-align: center;
	}

	&__link {
		color: inherit;
		text-decoration: none;
		margin: 0 0.5em;
	}
}

@include IE-only() {
	.site-footer__content { grid-row: 1; }
	.meta-navigation { grid-row: 2; }
}
