.hero-slider {
	.flickity-viewport {
		overflow: visible;
	}

	&__headline {
		font-size: inherit;
		margin: inherit;
		font-weight: inherit;
	}

	&__slide {
		position: relative;
		width: 100%;
		margin: 0;
		padding: 0;

		&-image-wrapper {
			position: relative;
			width: 100%;
			height: 30vw;
		}

		&-image {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&-caption {
			@include fluid-type(768px, 1340px, 18px, 28px);

			position: absolute;
			bottom: 0;
			right: 0;
			max-width: 60ch;

			background-color: rgba($color-primary, 0.8);
			color: #FFF;
			text-align: right;
			padding: 0.5em 1em;
			margin: 0;
			font-weight: normal;

			@include breakpoint-max(sm) {
				max-width: none;
				position: relative;
			}

			@include breakpoint-min(sm) {
				bottom: 10%;
			}
		}
	}
}

html:not(.no-js) .hero-slider{
	.hero-slider__slide + .hero-slider__slide {
		display: none;
	}

	.flickity-viewport .hero-slider__slide + .hero-slider__slide {
		display: block;
	}
}
