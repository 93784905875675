.text-image {
	$text-image: &;

	@include text-image-grid();
	position: relative;

	&__text {
		max-width: 72rem;
	}


	&__figure {
		overflow: hidden;
		display: block;
		margin: 0;
	}

	&__image {
		width: 100%;
	}

	&__image-caption {
		//@include figure-caption;
		margin-top: 0.5em;
	}
}

.text-image {
	$module: &;

	&__figure {
		height: 100%;

		&.-cover {
			position: relative;

			#{$module} {
				&__image {
					position: absolute;

					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}

		&.-full-height {
			position: relative;
			height: 100%;
			width: 100%;
			overflow: hidden;

			#{$module} {
				@include breakpoint-min(sm) {
					&__image-wrapper {
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 100%;
					}
				}

				&__image {
					object-fit: contain;
					object-position: top center;
					height: 100%;
					width: 100%;
				}
			}
		}

		&.-full-width {
			#{$module} {
				&__image {
					object-fit: contain;
					width: 100%;
					height: auto;
					align-self: center;
				}
			}
		}

		&.-align-center,
		&.-align-left,
		&.-align-right {
			display: grid;
			align-items: start;

			#{$module} {
				&__image {
					width: auto;
					height: auto;

					max-width: 100%;
				}
			}
		}

		&.-align-center {
			justify-content: center;
		}

		&.-align-left {
			justify-content: start;
		}

		&.-align-right {
			justify-content: end;
		}
	}
}
