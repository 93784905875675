@mixin text-image-grid() {
	$text-image: &;

	@include breakpoint-min(sm) {
		@include grid(1fr 1fr);

		@include IE-only {
			@include grid(1fr $grid-gap 1fr);
		}
	}

	@include IE-only {
		@include breakpoint-max(sm) {
			&__figure[class] {
				margin-top: 2 * $grid-gap;
			}
		}
	}

	@include breakpoint-min(md) {
		&.-layout-33-66, &.-layout-25-75 {
			grid-template-columns: 1fr 2fr;
			@include IE-only() { grid-template-columns: 1fr $grid-gap 2fr }
		}
		&.-layout-66-33, &.-layout-75-25 {
			grid-template-columns: 2fr 1fr;
			@include IE-only() { grid-template-columns: 2fr $grid-gap 1fr }
		}
	}

	@include breakpoint-min(xl) {
		&.-layout-25-75 {
			grid-template-columns: 1fr 3fr;
			@include IE-only() { grid-template-columns: 1fr $grid-gap 3fr }
		}
		&.-layout-75-25 {
			grid-template-columns: 3fr 1fr;
			@include IE-only() { grid-template-columns: 3fr $grid-gap 1fr }
		}
	}

	@include IE-only() {
		&__figure { grid-column: 3 }
		&__text { grid-column: 1 }
	}

	&.-image-left {
		#{$text-image} {
			&__figure, &__text {
				grid-row: 1;
			}

			&__figure {
				grid-column: 1;
				@include IE-only() { grid-column: 1 }
			}

			&__text {
				grid-column: 2;
				@include IE-only() { grid-column: 3 }
			}
		}
	}
}
