.cookie-consent-info {
	width: 100%;
	position: relative;
	text-align: center;
	font-weight: bold;
	grid-row: 1;
	grid-column: 1;
	z-index: 2;

	&__footer {
		margin-top: 1rem;
	}
}
