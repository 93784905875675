.product-teaser {

  max-width: 30rem;

  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    position: relative;
    //padding-bottom: 100%;
    padding-bottom: 23rem;
  }

  &__title {
    min-height: 3rem;
		margin-bottom: 0.5rem;
  }

	&__content-link {
			@include unstyled-link();
	}

  &__description {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }

  &__publication-date {
    @include copy-xs;
    display: block;
    margin-bottom: 0.5em;
    margin-top: 3rem;

  }

  &__publication-date + .product-teaser__title {
    margin-top: 0;
  }

  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;

    &-wrapper {
      max-height: 23rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: content-box;
      padding: 2rem;
      //background-color: #FFF;
    }
  }

  &__footer {
    text-align: center;
    margin-top: auto;
    margin-bottom: 0;
  }

  &__link {
    text-decoration: none;
    color: inherit;
    transition: none;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    font-weight: bold;
    color: color(secondary);
  }
}
