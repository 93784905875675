.module_interactive-map {

  &__content {
    @include site-row-full-width();
  }

	.interactive-map {
		min-width: 300px;
		height: 50vh;
	}
}
