.news {

  &__header {
    @include site-row();
    text-align: left;
  }

  &__heading, &__text {
    @include site-row-main-content();
  }

  &__content {
    @include site-row-main-content();

		.product-teaser__header {
			background-color: color(wild-sand);
		}
  }

  &__heading {
    @include fluid-type(768px, 1340px, 22px, 40px);
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 700;
    color: $color-primary;
  }
}
