@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'), local('Roboto-Regular'),
		url('/assets/fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'),
		url('/assets/fonts/roboto/roboto-v20-latin-regular.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'),
		url('/assets/fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'),
		url('/assets/fonts/roboto/roboto-v20-latin-500.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'),
		url('/assets/fonts/roboto/roboto-v20-latin-700.woff2') format('woff2'),
		url('/assets/fonts/roboto/roboto-v20-latin-700.woff') format('woff');
	font-display: swap;
}

body {
	@include font-copy;

	font-size: 160%;
	line-height: 1.5;
	color: color(copytext);
}

.page-title {
	@include font-display;

	font-size: 8rem;
	line-height: 1.05;
	color: color(secondary);

	&.-small {
		max-width: 70rem;
	}
}

.page-intro {
	font-weight: 300;
}

.rich-text {
	a:not(.button) {
		color: color(secondary);
		text-decoration: none;
	}

	.button {
		margin-top: 1em;
	}

	p {
		margin-top: 0;
	}

	figure {
		display: grid;
		margin: 2em auto;
		max-width: 100%;
		grid-template-columns: 1fr auto 1fr;
		justify-content: center;

		&:first-child { margin-top: 0 }
		&:last-child { margin-bottom: 0 }

		img, a {
			display: block;
			max-width: 100%;
			grid-column: 2;
			margin-left: auto;
			margin-right: auto;
		}

		&.left,
		&.right,
		&.full-width {
			grid-template-columns: auto;
		}

		&.left {
			justify-content: start;

			img {
				margin-left: initial;
				margin-right: auto;
			}
		}

		&.right {
			justify-content: end;

			img {
				margin-left: auto;
				margin-right: initial;
			}
		}

		&.full-width {
			display: block;

			img {
				width: 100%;
			}
		}

		figcaption {
			@include figure-caption;

			margin-top: 0.5em;
			grid-column: 2;
		}
	}

	.video {
		position: relative;
		border: none;
		width: 100%;
		max-width: none;
		padding-bottom: 100% * (9/16);

		&.has-placeholder {
			padding-bottom: 0;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	h2, h3, h4, h5 {
		margin-top: 2em;
		margin-bottom: 1em;

		&:first-child {
			margin-top: 0;
		}
	}

	h6 {
		margin-top: 2em;
		margin-bottom: 0;
	}

	h2 {
		@include heading-2;
		//text-transform: uppercase;
		color: color(primary);
		margin-top: 1.5em;
	}

	h3 {
		@include heading-3;
		color: color(primary);
		margin-top: 1.5em;
		//text-align: center;
	}

	h4 {
		@include heading-4;
		margin-top: 1.5em;
	}

	h5, h6 {
		font-size: 1em;
		font-weight: bold;
		margin-top: 1.5em;
	}

	h5 {
		color: color(copytext-dark);
	}

	h6 {
		margin-bottom: 0;
	}

	&.single-column {
		p, h2, h3, h4, h5, h6, ul, ol {
			max-width: 70rem;
			//margin-left: auto;
			//margin-right: auto;
		}
	}

	.intro {
		@include copy-lg;
		@include font-copy-light;
	}
}
