.news-entry {

  &__header {
    @include site-row();
    text-align: left;
  }

  &__publication-date {
    @include site-row-main-content();
    margin-bottom: 2rem;
  }

  &__header {
    @include site-row();
  }

  &__heading,
  &__subheading {
    @include site-row-main-content();
    line-height: 1.1;
    font-weight: normal;
  }

  &__heading {
    @include fluid-type(768px, 1340px, 22px, 40px);
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 700;
    color: $color-primary;
  }

  &__subheading {
    @include fluid-type(768px, 1340px, 18px, 24px);
    margin-top: 0;
    color: $color-primary;
  }

  &__teaser-text {
    @include site-row-main-content();
  }
}
