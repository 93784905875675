.site-footer {
	.image-wrapper.download {
		display: block;
		margin-bottom: 0.5rem;

		img {
			display: block;
			margin: 0 auto;
			max-width: 80%;
			max-height: 15rem;
		}

		+ p {
			text-align: center;
		}
	}
}

.inline-logo {
	img, svg {
		height: 1em;
		width: auto;
	}

	&__title {
		@include sr-only();
	}

	svg {
		fill: currentColor !important;

		path {
			fill: currentColor !important;
		}
	}
}

.default, .home {
	.main	{
		@include breakpoint-max(md) {
			margin-top: var(--header-offset);
		}
	}
}

.legal, .news-entry, .news {
	.main {
		margin-top: var(--header-offset);
	}

	.main__content > *:first-child {
		margin-top: var(--spacing-before-sm) !important;
	}
}

.legacy-browser-notice {
	@include site-row();
	display: none;

	grid-row: 4;

	background-color: $color-secondary;
	color: white;
	text-align: center;

	padding: 1.5rem;
	font-size: 1.4rem;

	@include IE-only() {
		display: block;
	}

	&__content {
		grid-column: 2;
	}
	.rich-text {
		p { margin: 0; }
		a { color: $color-primary; text-decoration: underline }
	}
}
