.main__title {
	@include site-row();
}

.main__heading {
	@include site-row-main-content();
	@include fluid-type(768px, 1340px, 22px, 40px);

	line-height: 1.1;
	color: $color-secondary;
	text-align: left;
	margin: 1.5em 0;
}
