:root {
  --module-spacing-default: 10rem;
  --module-spacing-sm: calc(var(--module-spacing-default) / 2);
  --module-spacing-md: var(--module-spacing-default);
  --module-spacing-lg: calc(var(--module-spacing-default) * 1.5);
  --module-spacing-neg-sm: calc(-1 * var(--module-spacing-sm));
  --module-spacing-neg-md: calc(-1 * var(--module-spacing-md));
  --module-spacing-neg-lg: calc(-1 * var(--module-spacing-lg));

  --spacing-before-default: var(--module-spacing-default);
  --spacing-after-default: var(--module-spacing-default);

  --spacing-before-none: 0;
  --spacing-after-none: 0;

  --spacing-before-sm: var(--module-spacing-sm);
  --spacing-after-sm: var(--module-spacing-sm);

  --spacing-before-md: var(--module-spacing-md);
  --spacing-after-md: var(--module-spacing-md);

  --spacing-before-lg: var(--module-spacing-lg);
  --spacing-after-lg: var(--module-spacing-lg);

  --spacing-before-neg-sm: var(--module-spacing-neg-sm);
  --spacing-after-neg-sm: var(--module-spacing-neg-sm);

  --spacing-before-neg-md: var(--module-spacing-neg-md);
  --spacing-after-neg-md: var(--module-spacing-neg-md);

  --spacing-before-neg-lg: var(--module-spacing-neg-lg);
  --spacing-after-neg-lg: var(--module-spacing-neg-lg);

  --horizontal-site-padding: 1.5rem;

  --header-padding: 1.5rem;
  --header-offset: #{9rem + 2rem};
	--logo-height: 3rem;

  @include breakpoint-min(sm) {
    --horizontal-site-padding: 2rem;
  }

  @include breakpoint-min(md) {
    --horizontal-site-padding: 3rem;
		--header-padding: 2rem;
    --header-offset: #{8rem + 2rem};
		--logo-height: 4.3rem;
  }

  @include breakpoint-min(lg) {
    --horizontal-site-padding: 4rem;
  }
}

::selection {
  color: #FFFFFF;
  background-color: $color-secondary;
  opacity: 1;
}

[hidden] {
	display: none;
}

.site {
  display: grid;
  min-height: 100%;
  width: 100%;

  grid-template-rows: auto 1fr auto;
	grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "main"
    "footer";

  &-header {
    grid-area: header;
    height: var(--header-offset);
  }

  &-footer {
    grid-area: footer;
  }

  main {
    grid-area: main;
  }

  //.main-navigation {
  //  grid-area: navigation;
  //}
}

.main__header {
	text-align: center;
}

.visually-hidden {
	@include sr-only();
}

[id] {
	scroll-margin-top: calc(1rem + var(--header-offset));
}
