$colors-text-image: (
	bg-default: transparent
);

.module_text-image {

	--background-color: map-get($colors-text-image, bg-default);

	&__header {
		grid-column: 1;
		margin-bottom: 0;
		text-align: left;
	}

	&__heading {
		margin-bottom: 0.2em;
	}

	&__content {
		@include site-row-main-content();
		@include text-image-grid();
	}

	&__text-image {
		@include breakpoint-min(sm) {
			grid-column: 1 / span 2;
		}
	}

	background-color: var(--background-color);

	@include IE-only() {
		&__heading {
			margin-bottom: 0.5em;
		}

		&__header {
			grid-row: 1;
			grid-column: 1;
		}

		&__text-image {
			grid-row: 2;

			@include breakpoint-min(sm) {
				grid-column: 1 / span 3;
			}
		}
	}
}
