.home {
	@include breakpoint-max(xs) {
		.news-teasers.product-teasers {
			&__content {
				grid-gap: 1.5rem;
			}

			.product-teaser {
				&__header,
				&__description,
				&__footer {
					display: none;
				}

				&__publication-date {
					margin: 0;
				}

				&__content-link {
					&:hover,
					&:active,
					&:focus {
						color: color(secondary);
					}
				}
			}
		}
	}
}
