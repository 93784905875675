$colors-container: (
	bg-default: transparent
);


.module_container {
	--background-color: map-get($colors-container, bg-default);

	background-color: var(--background-color);

	&:last-child { margin-bottom: 0; }

	&__content {
		> .module {
			display: block;
		}
	}
}
