#CybotCookiebotDialog {
	top: auto !important;
	bottom: -2px !important;
}

#CybotCookiebotDialog ~ .site .site-footer .meta-navigation {
	padding-bottom: 27rem;

	@include breakpoint(#{em(680px)}) {
		padding-bottom: 22rem;
	}
}
