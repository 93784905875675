@mixin site-row-main-content() {
	grid-column: 2 / span 1;
}

@mixin site-row-full-width() {
	grid-column: 1 / span 3;
}

@mixin site-row() {
	@include prevent-grid-expanding();

	display: grid;
	grid-gap: 0;
	grid-template-columns:
		minmax(var(--horizontal-site-padding), 1fr)
		minmax(0, $max-content-width)
		minmax(var(--horizontal-site-padding), 1fr);
	//grid-template-areas: 'left main-content right';
	width: 100%;

	//&__content {
	//  @include site-row-main-content();
	//  grid-row: auto;
	//}

	@media print {
		display: block;

		> * { width: 100% };
	}
}