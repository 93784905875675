$max-content-width: 128rem;
$grid-gap: 3rem;

$layers: (
	header: 100
);

$breakpoints: (
  min: em(320px),
  xxs: em(480px),
  xs: em(560px),
  sm: em(680px),
  md: em(768px),
  lg: em(1024px),
  xl: em(1280px),
  xxl: em(1440px),
  max: em(1920px),
);

$breakpoints-layout: (
	header-sm: bp(sm),
	header: bp(md),
);

$breakpoints: map-merge($breakpoints, $breakpoints-layout);
