.interactive-map {
	position: relative;
	height: 100%;
	background-color: color(iron);

	&__container {
		position: relative;
		height: 100%;
		min-width: 300px;
	}

	&__cookie-info {
		display: none;
	}
}

.interactive-map__marker-info-windows {
	display: none;
}

.interactive-map {
	&__cookie-info {
		display: grid;
		position: relative;
		height: 100%;
		width: 100%;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		align-items: center;
		justify-items: center;
	}

	&__cookie-background {
		position: relative;
		height: 100%;
		width: 100%;
		overflow: hidden;
		grid-row: 1;
		grid-column: 1;
		z-index: 1;

		svg {
			height: 100%;
			width: 100%;
		}
	}
}
