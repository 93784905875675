.contact-header__hero {
	position: relative;
	width: 100%;
	height: 30vw;
	min-height: 56rem;

	&-background {
		display: block;
		width: 100%;
		z-index: 1;
	}

	&-title {
		@include fluid-type(768px, 1340px, 18px, 28px);

		position: absolute;
		bottom: 10%;
		right: 0;
		background-color: rgba($color-primary, 0.8);
		color: #FFF;
		text-align: right;
		padding: 0.5em 1em;
		font-weight: normal;
		z-index: 2;
	}
}

.locations {
	$locations: &;

	@include unstyled-list(false);

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
	grid-gap: $grid-gap;

	&__location {
		margin: 0;
		padding: 0;

		padding: 3rem;
		margin: 1rem;
		background: color(wild-sand);
	}

	@include IE-only() {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		&__location {
			min-width: 35rem;
			max-width: 50rem;
			width: calc(33.33% - #{$grid-gap});
			margin: $grid-gap/2;
		}
	}

	&__location-content {
		#{$locations} {

			&__location-title {
				text-align: left;
			}

			&__location-opening-hours {
				font-size: 1.2em;
				margin-bottom: 0.3em;
				font-weight: 500;
				color: $color-primary;
			}
		}
	}
}
