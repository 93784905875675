$layers: () !default;
$breakpoints: () !default;

@function layer($name) {
  @return map-get($layers, $name);
}

@function bp($breakpoint-name, $min-width: true) {
  $bp-value: map-get($breakpoints, $breakpoint-name);

  @if $min-width != true {
    $bp-value: $bp-value - em(1px);
  }

  @return $bp-value;
}

@mixin export-bp($breakpoint-name) {
  display: none;
  content: '#{map-get($breakpoints, $breakpoint-name)}';
}

@mixin breakpoint-min($min, $custom-breakpoint: false) {
  @if $custom-breakpoint == true {
    @include breakpoint($min) {
      @content;
    }
  } @else {
    @include breakpoint(bp($min)) {
      @content;
    }
  }
}

@mixin breakpoint-max($max, $custom-breakpoint: false) {
  @if $custom-breakpoint == true {
    @include breakpoint(max-width $max) {
      @content;
    }
  } @else {
    @include breakpoint(max-width bp($max, max-width)) {
      @content;
    }
  }
}

@mixin center-vertically() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-horizontally() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center() {
  @include center-vertically();
  @include center-horizontally();

  transform: translateX(-50%) translateY(-50%);
}

@mixin prevent-grid-expanding() {
  // Prevent content from expanding grid items
  // @see https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
  min-height: 0;
  min-width: 0; // needed for Firefox

  > * {
    //overflow: hidden;
    min-width: 0; // needed for Firefox
  }
}

@mixin grid($template-columns) {
  @include prevent-grid-expanding();

  display: grid;
  grid-gap: $grid-gap;
  grid-template-columns: $template-columns;
}

@mixin arrow($size, $color, $border-width: 0, $border-color: transparent) {
  position: relative;

  &:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba($color, 0);
    border-width: $size;
    z-index: +1;
  }

  @if ($border-width > 0) {
    &:before {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba($border-color, 0);
      border-width: $size + $border-width;
      z-index: +1;
    }
  }
}

@mixin arrow-right($size, $color, $border-width: 0, $border-color: transparent) {
  @include arrow($size, $color, $border-width, $border-color);

  &:after {
    left: 100%;
    top: 50%;
    border-left-color: $color;
    margin-top: -$size;
  }

  @if ($border-width > 0) {
    &:before {
      left: 100%;
      top: 50%;
      border-left-color: $color;
      margin-top: -$size - $border-width;
    }
  }
}

@mixin arrow-left($size, $color, $border-width: 0, $border-color: transparent) {
  @include arrow($size, $color, $border-width, $border-color);

  &:after {
    right: 100%;
    top: 50%;
    border-right-color: $color;
    margin-top: -$size;
  }

  @if ($border-width > 0) {
    &:before {
      right: 100%;
      top: 50%;
      border-right-color: $color;
      margin-top: -$size - $border-width;
    }
  }
}

@mixin arrow-bottom($size, $color, $border-width: 0, $border-color: transparent) {
  @include arrow($size, $color, $border-width, $border-color);

  &:after {
    top: 100%;
    left: 50%;
    border-top-color: $color;
    margin-left: -$size;
  }

  @if ($border-width > 0) {
    &:before {
      top: 100%;
      left: 50%;
      border-top-color: $border-color;
      margin-left: -$size - $border-width;
    }
  }
}

@mixin arrow-top($size, $color, $border-width: 0, $border-color: transparent) {
  @include arrow($size, $color, $border-width, $border-color);

  &:after {
    bottom: 100%;
    left: 50%;
    border-bottom-color: $color;
    margin-left: -$size;
  }

  @if ($border-width > 0) {
    &:before {
      bottom: 100%;
      left: 50%;
      border-bottom-color: $border-color;
      margin-left: -$size - $border-width;
    }
  }
}

@mixin sr-only {
	position: absolute;
	white-space: nowrap;
	width: 1px;
	height: 1px;
	overflow: hidden;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	margin: -1px;
}

@mixin IE-only() {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}
