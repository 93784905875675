.module_product-teasers {

  //&__heading {
  //  margin-top: 0;
  //  color: color(dove-gray);
  //  font-size: 5rem;
  //  line-height: 1.1;
  //  letter-spacing: 0.03em;
  //  max-width: 30ch;
  //  margin-bottom: 1em;
  //  margin-left: auto;
  //  margin-right: auto;
  //  text-transform: uppercase;
  //  text-align: center;
  //}

  &__content {
    @include site-row-main-content();
  }

}
