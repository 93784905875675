$colors: (
	white: #FFF,
	madison: #0c365c,
	cerulean: #079fde,
	bahama-blue: #006690,
	dove-gray: #666,
	wild-sand: #F4F4F4,
	iron: #e7e7e8,
	silver: #84888B
);

$colors-brand: (
	primary: color(madison),
	secondary: color(cerulean),
	secondary-dark: color(bahama-blue),
	copytext: color(dove-gray)
);

// TODO: alle Vorkommnisse austauschen und löschen
$colors-legacy: (
	neutral-light: color(iron)
);

$colors: map-merge($colors, $colors-brand);
$colors: map-merge($colors, $colors-legacy);

$color-primary: color(primary);
$color-secondary: color(secondary);
