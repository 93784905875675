.module_text {
	&__content-wrapper, details {
		@include site-row-main-content();
	}

	&__text, details {
		max-width: 80ch;
	}

	$summary-toggle-icon-more: 'data:image/svg+xml,\
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 768" fill="%23079fde" width="28px" height="28px">\
				<path d="M736 384c0-97.184-39.424-185.248-103.104-248.896S481.184 32 384 32 198.752 71.424 135.104 135.104 32 286.816 32 384s39.424 185.248 103.104 248.896S286.816 736 384 736s185.248-39.424 248.896-103.104S736 481.184 736 384zm-64 0c0 79.552-32.192 151.488-84.352 203.648S463.552 672 384 672s-151.488-32.192-203.648-84.352S96 463.552 96 384s32.192-151.488 84.352-203.648S304.448 96 384 96s151.488 32.192 203.648 84.352S672 304.448 672 384zm-416 32h96v96c0 17.664 14.336 32 32 32s32-14.336 32-32v-96h96c17.664 0 32-14.336 32-32s-14.336-32-32-32h-96v-96c0-17.664-14.336-32-32-32s-32 14.336-32 32v96h-96c-17.664 0-32 14.336-32 32s14.336 32 32 32z"/>\
			</svg>';
	$summary-toggle-icon-less: 'data:image/svg+xml,\
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 768" fill="%23079fde" width="28px" height="28px">\
				<path d="M736 384c0-97.184-39.424-185.248-103.104-248.896S481.184 32 384 32 198.752 71.424 135.104 135.104 32 286.816 32 384s39.424 185.248 103.104 248.896S286.816 736 384 736s185.248-39.424 248.896-103.104S736 481.184 736 384zm-64 0c0 79.552-32.192 151.488-84.352 203.648S463.552 672 384 672s-151.488-32.192-203.648-84.352S96 463.552 96 384s32.192-151.488 84.352-203.648S304.448 96 384 96s151.488 32.192 203.648 84.352S672 304.448 672 384zm-416 32h256c17.664 0 32-14.336 32-32s-14.336-32-32-32H256c-17.664 0-32 14.336-32 32s14.336 32 32 32z"/>\
			</svg>';

	details {
		summary {
			text-align: center;
			margin-bottom: 3rem;
			cursor: pointer;
			font-size: 1.8rem;
			line-height: 1.6;
			color: $color-secondary;

			list-style: none;

			&:focus {
				outline: none;
			}

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 1.6em;
				height: 1.6em;
				background-image: url($summary-toggle-icon-more);
				color: transparent;
			}

			span {
				vertical-align: middle;
			}
		}

		.module_text__toggle-button-less {
			display: none;
		}

		summary::marker {
			display: none;
		}

		summary::-webkit-details-marker {
			display: none;
		}

		&[open] {
			summary:before {
				background-image: url($summary-toggle-icon-less);
			}

			.module_text__toggle-button-more {
				display: none;
			}

			.module_text__toggle-button-less {
				display: inline;
			}
		}

		@include IE-only() {
			summary { display: none }
		}
	}
}
