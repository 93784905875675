.product-teasers {

  min-height: 0;
  min-width: 0;

  &__content {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  }

	@include IE-only() {
		&__content {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			> * {
				padding: $grid-gap/2;
				min-width: 230px;
				max-width: 450px;
				width: 25%;
			}
		}
	}

}
