.site-header {
	@include site-row();

	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	padding: var(--header-padding) 0;

	z-index: layer(header);

	color: #FFF;
	background-color: rgba($color-primary, 1);
	border-bottom: 12px solid $color-secondary;

	@include breakpoint-min(lg) {
		background-color: rgba($color-primary, 0.9);
	}

	&__content {
		@include site-row-main-content();
		text-align: center;

		@include breakpoint-min(header) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: left;
		}
	}

	&__logo {
		display: inline-block;
		height: var(--logo-height);
		height: 3rem;
		width: auto;

		@include breakpoint-min(sm) {
			height: 4.3rem;
		}

		svg { width: 100%; height: 100%; }
	}
}

// TODO: auslagern
.main-navigation {
	$main-nav: &;

	margin-top: 0.3rem;

	@include breakpoint-max(header-sm) {
		margin: 0 2vw;
		margin-top: 0.3rem;

		height: 1.5em;
		overflow-y: hidden;
	}

	@include breakpoint-min(header) {
		margin-top: 0;
		text-align: left;
	}

	&__entries {
		@include unstyled-list(true);


		@include breakpoint-max(header-sm) {
			white-space: nowrap;
			overflow-x: scroll;
			width: 100%;
			padding-right: 1em;
			border: 1px solid $color-primary;

			background-image: linear-gradient(to right, $color-primary, hsla(208, 77%, 20%, 0)),
												radial-gradient(at left, rgba(0, 0, 0, 0.7), transparent 70%),
												linear-gradient(to left, $color-primary, hsla(208, 77%, 20%, 0)),
												radial-gradient(at right, rgba(0, 0, 0, 0.7), transparent 70%);
			background-repeat: no-repeat;
			background-size: 15px 100%;
			background-position: left center, left center,
													 right center, right center;
			background-attachment: local, scroll, local, scroll;
		}

		@include breakpoint-min(header) {
			display: flex;
			justify-content: flex-start;
		}


		@include breakpoint-min(lg) {
			font-size: 1.8rem;
		}
	}

	&__entry {
		display: inline-block;

		+ #{$main-nav}__entry {
			margin-left: 1.5em;
		}

		@include breakpoint-max(header-sm) {
			+ #{$main-nav}__entry {
				margin-left: 0.8em;
			}
		}
	}

	&__link {
		@include unstyled-link();
		color: #FFF;
	}
}
