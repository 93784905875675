@import "normalize";

*, *:before, *:after {
	box-sizing: border-box;
}

html, body {
	height: 100%;
	max-width: 100%;
}

html {
	font-size: ($root-font-size / 16px) * 100%;
}

body, main {
	width: 100%;
	overflow-x: hidden;
}

img {
	display: block;
}

address {
	font-style: normal;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
	font-family: inherit; /* 1 */
	font-size: 100%; /* 1 */
	color: inherit;
	line-height: 1.15; /* 1 */
	margin: 0; /* 2 */
}

button {
	cursor: pointer;
}

.modal {
	visibility: hidden;
}

h5, h6 {
	font-size: 1em;
	margin-bottom: 0.1em;
}
