$colors-gallery: (
	bg-caption: rgba(color(primary), 0.5),
	text-caption: color(white)
);

.gallery {
	$gallery: &;

	&.-maximum {
		#{$gallery} {
			&__entries {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}

			&__entry {
				padding: 3%;

				&-image {
					min-height: 2rem;
				}
			}
		}
	}

	&.-columns {
		#{$gallery} {
			&__entries {
				display: grid;
				grid-column-gap: 1rem;
				grid-row-gap: 1rem;

				@include breakpoint-min(xs) {
					grid-template-columns: 1fr 1fr;
				}

				@include breakpoint-min(lg) {
					grid-template-columns: 1fr 1fr 1fr 1fr;
				}
			}

			&__entry {
				&-image {
					width: 100%
				}
			}
		}
	}
}

.gallery__entries {
	@include unstyled-list();
}

.gallery__entry {
	$entry: &;

	&-figure {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
	}

	&-image {
		//width: 100%;
	}

	&-image-caption {
		@include figure-caption();

		position: absolute;
		top: auto;
		right: 0;
		bottom: 0;
		left: 0;

		margin-top: 0.5em;
		padding: 0.5em;

		background-color: color(bg-caption, $colors-gallery);
		color: color(text-caption, $colors-gallery);

		transition: transform transition(default);
		transform: translateY(100%);
	}

	&:hover {
		#{$entry}-image-caption {
			transform: translateY(0);
		}
	}
}
