@mixin font-copy {
	font-family: $base-font-family;
	font-weight: normal;
	font-style: normal;
}

@mixin font-bold {
	font-family: $base-font-family;
	font-weight: bold;
	font-style: normal;
}

@mixin font-copy-light {
	font-family: $base-font-family;
	font-weight: 300;
	font-style: normal;
}

@mixin font-headings-light {
	font-family: $headings-font-family;
	font-weight: 400;
	font-style: normal;
}


@mixin font-headings {
	font-family: $headings-font-family;
	font-weight: normal;
	font-style: normal;
}

@mixin font-display {
	//font-family: $display-font-family;
	font-weight: bold;
	font-style: normal;
}

@mixin heading {
	@include font-headings;
	margin-top: 0;
	color: color(copytext-dark);
}

@mixin heading-1 {
	@include heading;
	font-size: 5rem;
	line-height: 1.1;
}

@mixin heading-2 {
	@include heading;
	font-size: 3.6rem;
	line-height: 1.2;
}

@mixin heading-3 {
	@include heading;
	font-size: 2.6rem;
	line-height: 1.2;
}

@mixin heading-4 {
	@include heading;
	font-size: 2.4rem;
	line-height: 1.16;
}

@mixin heading-5 {
	font-size: 1em;
	font-weight: bold;
	line-height: 1.2;

	margin-top: 1.5em;
	margin-bottom: 0;

	&:first-child {
		margin-top: 0;
	}
}

@mixin copy-xs {
	font-size: 1.4rem;
}

@mixin copy-xxs {
	font-size: 1.3rem;
}

@mixin copy-lg {
	font-size: 2.2rem;
	color: color(copytext-dark);
}

@mixin figure-caption {
	font-size: 1.4rem;
	font-style: italic;
	color: color(silver-chalice);
}

@mixin intro-text() {
	@include copy-lg;
	@include font-copy-light;
}

@mixin teaser-link() {
	@include unstyled-link();

	text-transform: uppercase;
	letter-spacing: 0.07em;
	font-weight: bold;
	color: color(secondary);

	&:hover, &:focus {
		text-decoration: underline;
		color: color(secondary);
	}
}

@mixin module-heading() {

}
