$colors-button: (
	bg: color(secondary),
	bg-hover: color(secondary-dark),
	border: color(secondary-dark),
	text: color(wild-sand),

	bg--cta: color(secondary),
	bg-hover--cta: color(secondary-dark),
	border--cta: color(secondary-dark),
	text--cta: color(white)
);

.button {
	@include unstyled-link();

	--background-color: #{map-get($colors-button, bg)};
	--background-color-hover: #{map-get($colors-button, bg-hover)};
	--color: #{map-get($colors-button, text)};
	--border-color: #{map-get($colors-button, border)};

	padding: 0.75em 1em;

	background-color: var(--background-color);
	color: var(--color) !important;
	border: 1px solid var(--border-color);

	&:hover, &:active {
		background-color: var(--background-color-hover);
	}
}

.button--cta {
	--background-color: #{map-get($colors-button, bg--cta)};
	--color: #{map-get($colors-button, text--cta)};
	--border-color: #{map-get($colors-button, border--cta)};
}
