.module {
	@include site-row();

	--spacing-before: var(--module-spacing-default);
	--spacing-after: var(--module-spacing-default);

	margin: var(--spacing-before) 0 var(--spacing-after);

	&.has-background {
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 5rem;
		padding-bottom: 6rem;

		.module__heading {
			margin-top: 0;
		}
	}
}

.module {
	&.has-anchor {
		&:before {
			content: '';
			display: block;
			position: relative;
			width: 0;
			height: var(--header-offset);
			margin-top: calc(-1 * var(--header-offset));
		}
	}
}

.module {
	&__header {
		@include site-row-main-content();
		margin-bottom: 3rem;
	}

	&__content {
		@include site-row-main-content();
	}

	@include IE-only() {
		&__header { grid-row: 1; }
		&__content { grid-row: 2; }
	}

	&__heading,
	&__subheading {
		line-height: 1.1;
		font-weight: normal;
	}

	&__heading {
		@include fluid-type(768px, 1340px, 22px, 40px);
		margin-top: 0;
		margin-bottom: 0.5em;
		font-weight: 700;
		color: $color-primary;
		//font-size: 3rem;
	}

	&__subheading {
		@include fluid-type(768px, 1340px, 18px, 24px);
		margin-top: 0;
		color: $color-primary;
		//font-size: 2.4rem;
	}
}
