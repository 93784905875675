.news-teaser {
}

.module_news-teasers__content {
	.product-teaser__header {
		background-color: color(wild-sand);
	}
}

.has-background .module_news-teasers {
	.product-teaser__header {
		background-color: color(white);
	}
}
